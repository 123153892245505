.page {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
}

.page-title {
  padding: 8px;
  font-size: 18px;
  font-weight: bold;
}
.page-content {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  padding: 8px;
}
