.task-board-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
  column-gap: 8px;
}

.task-column {
  background-color: #e0e0e0;
  border-radius: 8px;
  min-width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.task-column.drop {
  background-color: #f0f066;
}

.task-card.drag,
.task-column.drag {
  opacity: 0.25;
  border: 1px dotted blue;
}
.task-column-content {
  flex-grow: 1;
  overflow: auto;
}

.task-column-header {
  font-weight: bold;
  font-size: 14px;
  padding: 4px;
}

.task-column-cards {
  background-color: #c0c0c0;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.task-card {
  background-color: #9999cc;
  border-radius: 8px;
  padding: 4px;
  height: 120px;
}
